import React, {useEffect, useRef, useState} from "react";
import Hls from "hls.js";

export default function Translet(data) {
    const videoRef = useRef(null);
    const [height, setHeight] = useState("auto");

    const src = data.data;
    useEffect(() => {
        const video = videoRef.current;

        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(video);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play();
            });

            hls.on(Hls.Events.ERROR, (event, data) => {
                console.error("HLS.js error:", data);
            });

            return () => {
                hls.destroy();
            };
        } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = src;
            video.addEventListener("loadedmetadata", () => {
                video.play();
            });

            return () => {
                video.removeEventListener("loadedmetadata", () => {
                    video.play();
                });
            };
        } else {
            console.error("HLS is not supported in this browser");
        }
    }, [src]);

    useEffect(() => {
        const updateHeight = () => {
            if (videoRef.current) {
                const width = videoRef.current.offsetWidth;
                setHeight(`${width * 0.6}px`);
            }
        };

        updateHeight();
        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, []);
    return (
        <video ref={videoRef} style={{ width: "100%", height: height }} controls />
    );
}
